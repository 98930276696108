// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming

@use "@angular/material" as mat;

@import "https://fonts.googleapis.com/css?family=Material+Icons";
@import url("https://fonts.googleapis.com/css?family=Rubik:300,400,500");

@include mat.core();

$dark-base-color: #29293b;
$accent-base-color: #ff4d7c;
$primary-base-color: #c3ff00;

$app-primary: (
  50: #f8ffe7,
  100: #eeffc3,
  200: #e0ff98,
  300: #d2fd6a,
  400: #c7fb41,
  500: $primary-base-color,
  600: #b9eb00,
  700: #aad400,
  800: #9cbc00,
  900: #869300,
  contrast: (
    50: $dark-base-color,
    100: $dark-base-color,
    200: $dark-base-color,
    300: $dark-base-color,
    400: $dark-base-color,
    500: $dark-base-color,
    600: $dark-base-color,
    700: white,
    800: white,
    900: white,
  ),
);

$app-accent: (
  50: #ffe6ed,
  100: #ffbfd1,
  200: #ff96b3,
  300: #ff6c95,
  400: $accent-base-color,
  500: #fe3466,
  600: #ec2f63,
  700: #d62a5f,
  800: #c1245c,
  900: #9c1b55,
  contrast: (
    50: $dark-base-color,
    100: $dark-base-color,
    200: $dark-base-color,
    300: $dark-base-color,
    400: $dark-base-color,
    500: white,
    600: white,
    700: white,
    800: white,
    900: white,
  ),
);

$app-palette-primary: mat.define-palette($app-primary, 500);
$app-palette-accent: mat.define-palette($app-accent, 400);

/* Styles to be applied to buttons */
$app-button-typography: mat.define-typography-level(
  $font-weight: 400,
  $font-size: 1rem,
  $letter-spacing: 'normal',
);

$app-typography-config: mat.define-typography-config(
  $button: $app-button-typography
);

$theme: mat.define-light-theme(
  (
    color: (
      primary: $app-palette-primary,
      accent: $app-palette-accent,
    ),
    typography: $app-typography-config,
  )
);

@include mat.all-component-themes($theme);

/**
 * Theme variables.
 *
 * All base colors in this palette are defined in the Style Guide.
 * You can add more colors here.
 * @see https://www.figma.com/file/3o0y1CJe5ylSbQKZWTXoJt/Llamando-al-doctor---Sin-patr%C3%B3n?node-id=1%3A177
 */
:root {
  --dark-base-color: #29293b;
  --dark-lighter-color: #bfbfc4;
  --dark-darker-color: #191926;
  --accent-base-color: #ff4d7c;
  --accent-lighter-color: #ffcad8;
  --accent-darker-color: #ff345f;
  --primary-base-color: #c3ff00;
  --primary-lighter-color: #edffb3;
  --primary-darker-color: #aeff00;
  --gray-base-color: #d8d8d8;
  --gray-lighter-color: #f3f3f3;
  --gray-darker-color: #c8c8c8;
  --gray-darker-color-v2: #4d4d61;
  --input-border-color: #e0e0e0;
  --purple-base-color: #7878f7;
  --red-base-color: #ff3a22;
  --success-base-color: #047B09;
  --success-lighter-color: #F2FDCF;
  --error-base-color: #F96041;
  --error-lighter-color: #FFE1DB;
  --black-alpha-70: #000000b3; /* 70% */
  --black-alpha-60: #00000099; /* 60% */
  --black-alpha-50: #00000080; /* 50% */
  --black-alpha-42: #0000006b; /* 42% */
  --black-alpha-35: #00000059; /* 35% */
  --black-alpha-10: #0000001a; /* 10% */
  --black-alpha-08: #00000014; /* 8% */
  --black-alpha-04: #0000000a; /* 4% */
}

.mat-mdc-checkbox.mat-primary {
  --mdc-checkbox-selected-checkmark-color: var(--dark-base-color);
}
