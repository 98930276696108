/* You can add global styles to this file, and also import other style files */
@use '@angular/material' as mat;

html, body { height: 100%; }

body, * {
    margin: 0;
    font-family: Rubik, "Helvetica Neue", sans-serif !important;
}

.hideLoader{
    display:none;
}
.showLoader{
    display:grid;
}

#loaderDiv {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 10000;
    background-color: rgba(0,0,0,0.2);
}

.loader {
    border: 8px solid #f3f3f3;
    border-top: 8px solid var(--accent-base-color);
    border-radius: 50%;
    width: 30px;
    height: 30px;
    animation: spin 2s linear infinite;
    bottom: 0;
    top: 0;
    right: 0;
    left: 0;
    margin: auto;
    z-index: 1000;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

.white-container {
    display: flex;
    flex-direction: column;

    box-shadow: 0px 4px 20px rgba(139, 141, 160, 0.2);
    border-radius: 30px;
    padding: 1rem 2rem;
}

.no-data-container {
    margin: 1.75rem auto 0;
    text-align: center;
    color: var(--dark-base-color);
    font-size: 1.4rem;
    font-weight: 300;
    line-height: normal;
}

@media only screen and (min-width: 600px) {
    .no-data-container {
        margin: 6rem auto 0;
    }
}

/* mat-label */

mat-label {
    font-weight: 300;
    font-size: 13px;
    color: var(--dark-base-color);
}

/* mat-form-field */

.mat-form-field-appearance-outline .mdc-notched-outline > * {
    border-color: var(--input-border-color) !important;
}

.mat-form-field-appearance-outline .mdc-notched-outline__trailing {
    border-radius: 0 26px 26px 0 !important;
}

.mat-form-field-appearance-outline .mdc-notched-outline__leading {
    border-radius: 26px 0 0 26px !important;
    width: 26px !important;
}

.mat-form-field-appearance-outline {
    @include mat.all-component-densities(-1);
}

.mat-form-field-appearance-outline,
.mat-mdc-select {
    font-weight: 300 !important;
    color: var(--dark-base-color);
}

mat-hint {
    color: #8B8DA0;
}

.mdc-text-field .mdc-text-field__input {
    caret-color: var(--dark-base-color) !important;
}

.mat-mdc-form-field .mdc-text-field--disabled {
    background-color: #F3F5F8;
    border-radius: 30px;
}

/* textarea */

.mat-mdc-form-field textarea {
    resize: none;
    border: none;
    font-size: 0.9rem !important;
    color: var(--dark-base-color);
    line-height: normal !important;
}

.mat-mdc-form-field textarea::-webkit-scrollbar {
    width: 4px;
    background-color: #EDEDF2;
}

.mat-mdc-form-field textarea::-webkit-scrollbar-thumb {
    border: none;
    border-radius: 0;
    background-color: #C7C7C9;
}

/* placeholder */

.mat-form-field-appearance-outline .mat-mdc-select-placeholder,
.mat-form-field-appearance-outline .mat-mdc-input-element::placeholder {
    font-weight: 300;
    font-size: 14px;
    color: #8B8DA0 !important;
}

/* mat-form-field error */

.mat-form-field-appearance-outline .mdc-text-field--invalid .mdc-notched-outline > * {
    border-color: var(--red-base-color) !important;
}

.mat-form-field-appearance-outline .mat-mdc-form-field-error {
    color: var(--red-base-color);
    line-height: normal;
}

/* mat-select */

.mat-mdc-select-panel.select-custom-panel {
    box-shadow: 0px 4px 20px rgba(139, 141, 160, 0.25) !important;
    border-radius: 0 0 23px 23px;
    border-top: none !important;
    border: 1px solid var(--gray-base-color);
}

.mat-mdc-select-panel.select-custom-panel .mat-mdc-option.mdc-list-item--selected:not(.mat-mdc-option-multiple):not(.mdc-list-item--disabled) {
    background: rgba(44, 44, 44, 0.12);
}

.mat-mdc-select-panel.select-custom-panel .mat-mdc-option.mdc-list-item--selected:not(.mat-mdc-option-multiple):not(.mdc-list-item--disabled) .mat-pseudo-checkbox {
    display: none;
}

.mat-mdc-select-panel.select-custom-panel .mat-mdc-option.mdc-list-item {
    font-weight: 300;
    color: var(--dark-base-color);
}

.select-suffix {
    color: #8B8DA0;
    width: 19px;
    padding-right: 1rem !important;
    cursor: pointer;
}

/* autocomplete */

.mat-mdc-autocomplete-panel {
    border-radius: 0 0 23px 23px !important;
    box-shadow: 0px 4px 20px rgba(139, 141, 160, 0.25) !important;
}

.mat-mdc-autocomplete-panel .mat-mdc-option.mdc-list-item {
    font-weight: 300;
    color: var(--dark-base-color);
}

.mat-mdc-autocomplete-panel .mat-mdc-option.mdc-list-item--selected:not(.mat-mdc-option-multiple):not(.mdc-list-item--disabled) .mat-pseudo-checkbox {
    display: none;
}

.mat-mdc-autocomplete-panel-above .mat-mdc-autocomplete-panel {
    border-radius: 23px 23px 0 0 !important;
    box-shadow: 4px 0px 20px rgba(139, 141, 160, 0.25) !important;
}


/* mat-snackbar (toast) */

.mat-mdc-snack-bar-container {
    margin-bottom: 1.5rem !important;

    .mdc-snackbar__surface {
        border-radius: 0.6rem;
        box-shadow: 0px 4px 12px 0px rgba(196, 196, 196, 0.67);
    }

    .mdc-snackbar__label {
        font-size: 1rem;
        --mdc-snackbar-supporting-text-line-height: normal;
    }
}

.mat-mdc-snack-bar-container.toast-error {
    .mdc-snackbar__surface {
        border: 1px solid var(--error-base-color);
        background-color: var(--error-lighter-color);
    }

    .mdc-snackbar__label {
        color: var(--error-base-color);
    }
}

.mat-mdc-snack-bar-container.toast-success {
    .mdc-snackbar__surface {
        border: 1px solid var(--success-base-color);
        background-color: var(--success-lighter-color);
    }

    .mdc-snackbar__label {
        color: var(--success-base-color);
    }
}

@media screen and (min-width: 600px) {
    .mat-mdc-snack-bar-container {
        margin-bottom: 2.5rem !important;
    }
}

.title-accent {
    font-weight: 300 !important;
    font-size: 16px !important;
    color: var(--accent-base-color);
}

/* dialog */

.mat-mdc-dialog-container .mdc-dialog__surface {
    border-radius: 20px !important;
}

.mat-mdc-dialog-content {
    max-height: 90vh !important;
    --mdc-dialog-supporting-text-tracking: normal;
}

.dark-dialog .mat-mdc-dialog-container .mdc-dialog__surface {
    background-color: var(--dark-base-color) !important;
    padding: 1rem;
}

.dark-dialog .mat-mdc-dialog-container .mdc-dialog__content {
    --mdc-dialog-supporting-text-color: white;
}

@media (min-width: 600px) {
    .file-dialog-container {
        max-width: 60vw !important;
        width: auto !important;
        min-width: 500px;
    }
}

/* input of type="number" */

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance:textfield;
}

/* scrollbar */

::-webkit-scrollbar-thumb {
    border: 3px solid transparent;
    border-radius: 10px;
    background-clip: padding-box;
    -webkit-background-clip: padding-box;
    background-color: var(--black-alpha-35);
}

.textarea ::-webkit-scrollbar {
    display: block !important;
}

/* datepicker */

.mat-datepicker-content {
    border-radius: 15px !important;
}

.mat-datepicker-content .mat-calendar {
    height: 370px !important;
}

/* button */

.mdc-button {
    border-radius: 30px !important;
    min-height: 48px;
}

.mdc-button.low {
    min-height: 36px;
    height: 36px;
    font-size: 0.85rem;

    .mat-icon.east, .mat-icon.west {
        min-width: 0 !important;
    }
}

.mdc-button.no-fill {
    background-color: transparent !important;
}

.mdc-button.mat-primary.no-fill {
    color: var(--primary-base-color) !important;
    border: 1px solid var(--primary-base-color) !important;
}

.mat-mdc-unelevated-button.mat-primary,
.mat-mdc-raised-button.mat-primary {
    --mdc-filled-button-label-text-color: var(--dark-base-color) !important;
}

.mat-mdc-unelevated-button.mat-warn,
.mat-mdc-raised-button.mat-warn {
    --mdc-filled-button-container-color: #f00 !important;
}

.east, .west {
    position: absolute !important;
    color: var(--dark-base-color);
    min-width: 1.5rem;
}

.east {
    right: 1rem;
}

.west {
    left: 1.25rem;
}

.mdc-button:disabled .east {
    color: #8B8DA0;
}

/* sections */
.section-container {
    display: flex;
    width: 100%;
    margin: 4.5rem 0 1.5rem;

    .section {
        flex: 1;
    }

    .section.center {
        min-width: 45%;
    }

    .section.center .content {
        width: 500px;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
    }

    .section.left .content {
        width: 80%;
        margin: 0 auto;
        text-align: center;
    }

    .section.left {
        padding-left: 2rem;
    }

    .section.right {
        padding-right: 2rem;
    }
}

@media (max-width: 700px) {
    .section-container {
      flex-direction: column;
      width: 90%;
      margin: 0 auto;

      .section.center .content {
        width: 100%;
      }

      .section.left .content {
        width: 100%;
        text-align: center;
        margin: 1.25rem auto;
      }

      .section.center,
      .section.left,
      .section.right {
        padding: 0;
      }
    }
}

/* Confetti */

.confetti {
    background-image: url("/assets/icons/confetti-sm.svg");
    background-repeat: no-repeat;
    background-size: auto;
    background-position: right 1rem;
    overflow: visible;
}

@media screen and (min-width: 960px) {
    .confetti {
        overflow: visible;
        background-repeat: no-repeat;
        background-image: url("/assets/icons/confetti-sm.svg");
        background-size: auto;
        background-position: right top;
    }
}

@media screen and (min-width: 1025px) {
    .confetti {
        background-image: url("/assets/icons/confetti-lg.svg");
        background-size: 100% auto;
        background-position: right top;
    }
}

/* tab */

.mat-mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label {
    color: var(--dark-base-color) !important;
    font-weight: 500;
}

/* checkbox */
.mat-mdc-checkbox {
    .mdc-checkbox__background {
        border: 1px solid #8B8DA0;
        border-radius: 5px;
    }

    label {
        color: var(--dark-base-color);
        font-weight: 300;
        line-height: normal;
    }

    .mdc-checkbox__checkmark {
        transform: scale(0.7);
    }
}

.mat-mdc-checkbox.read-only {
    label {
        color: var(--dark-base-color) !important;
    }

    .mdc-checkbox__background {
        transform: scale(1.1);
        background-color: transparent !important;
        border-color: var(--dark-base-color) !important;
    }

    .mdc-checkbox__checkmark {
        color: var(--dark-base-color) !important;
    }
}

.mat-mdc-checkbox.red {
    label {
        color: var(--red-base-color) !important;
    }

    .mdc-checkbox__background {
        border-color: var(--red-base-color) !important;
    }
}

.mat-mdc-checkbox-disabled.red {
    label {
        color: #8B8DA0 !important;
    }

    .mdc-checkbox__background {
        border-color: #8B8DA0 !important;
    }
}

/* radio-button */

.mat-mdc-radio-group {
    label {
        font-weight: 300;
    }

    .mdc-radio__outer-circle {
        border-width: 1px;
    }
}

/* warn-label */

.warn-label {
    color: var(--red-base-color);
    font-size: 0.9rem;
    font-weight: 500;
}

/* titles */
h1.title {
    color: var(--dark-base-color);
    font-size: 1.5rem;
    font-weight: 300;
}